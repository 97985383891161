import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "",
        "alt": null
      }}></img></p>
    <p>{`Rendez-vous devant vos écrans, Samedi 22 Mai, à 20h20 sur la chaîne de télévision FRANCE 24 pour l'émission Elément Terre !`}</p>
    <p>{`Explosion des livraisons, quantité colossale d'emballages.... la consigne s'invite dans l'équation !`}</p>
    <p>{`Interviews de Bastien Pahus (Uber Eats et Bioburger), Alice Elfassi (Zero Waste France) et Yasmine Dahmane (La Consigne GreenGo)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      